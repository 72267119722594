.react-datepicker-wrapper {
  width: 100%;
  position: relative;
  z-index: 2;
  > .react-datepicker__input-container {
    width: 100%;
    > input {
      width: 100%;
      border-width: 0;
      font-size: 13px;
      border-bottom: 1px solid;
      background-color: transparent;
    }
  }
}
.react-datepicker-popper {
  z-index: 5 !important;
}
