@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap);

.App-link {
  color: #09d3ac; }

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  background-color: #282c34;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden; }

img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */ }

* {
  outline: none; }

.top-menu {
  position: relative;
  z-index: 1000;
  margin: 15px; }
  .top-menu > .top-menu-hamburger {
    position: absolute;
    width: 40px;
    height: 32px;
    top: 0;
    left: 0;
    box-sizing: border-box; }
    .top-menu > .top-menu-hamburger > .bar {
      margin: 0 5px;
      width: 100%;
      position: absolute;
      height: 4px;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      left: 0;
      -webkit-transition: .7s;
      transition: .7s;
      border-radius: 2px;
      background-color: #e2e2e2; }
      @media screen and (max-width: 767px) {
        .top-menu > .top-menu-hamburger > .bar {
          background-color: #9fa1a4; } }
    .top-menu > .top-menu-hamburger .bar:first-child {
      -webkit-transform: unset;
              transform: unset;
      top: 0; }
    .top-menu > .top-menu-hamburger .bar:last-child {
      -webkit-transform: unset;
              transform: unset;
      top: unset;
      bottom: 0; }
  .top-menu > img.logo {
    height: 45px;
    position: absolute;
    left: 75px;
    top: -5px;
    -webkit-transition-duration: 1s;
            transition-duration: 1s;
    -webkit-transition-property: opacity;
    transition-property: opacity; }
    @media screen and (max-width: 767px) {
      .top-menu > img.logo {
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); } }
  .top-menu > img.logo.hide {
    opacity: 0; }

.top-menu.opened > .top-menu-hamburger > .bar:not(:first-child):not(:last-child) {
  opacity: 0; }

.top-menu.opened > .top-menu-hamburger > .bar:first-child {
  top: 50%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.top-menu.opened > .top-menu-hamburger > .bar:last-child {
  top: 50%;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

/* Set the background color of body to tan */
.left-menu {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 400px;
  height: 100%;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition-duration: 0.5s;
          transition-duration: 0.5s;
  z-index: 500;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#a98e5a), color-stop(70%, rgba(193, 171, 122, 0.66)), color-stop(98%, rgba(212, 193, 147, 0)));
  background-image: linear-gradient(to bottom, #a98e5a, rgba(193, 171, 122, 0.66) 70%, rgba(212, 193, 147, 0) 98%); }
  @media screen and (max-width: 767px) {
    .left-menu {
      width: 100%;
      background-color: #020202;
      background-image: unset;
      text-align: center; } }
  .left-menu > ul.menu-items {
    margin: 0;
    padding: 0;
    margin-top: 180px;
    margin-left: 15px;
    list-style: none;
    font-size: 1.5em;
    font-weight: bolder; }
    @media screen and (max-height: 700px) {
      .left-menu > ul.menu-items {
        margin-top: 50px !important;
        font-size: 1.3em; } }
    @media screen and (max-width: 767px) {
      .left-menu > ul.menu-items {
        margin-left: 0; } }
    .left-menu > ul.menu-items > li {
      margin: 0;
      padding: 0;
      line-height: 2.2em;
      -webkit-transition-duration: 0.2s;
              transition-duration: 0.2s; }
      .left-menu > ul.menu-items > li > a {
        color: #fff;
        text-decoration: none;
        display: block; }
    .left-menu > ul.menu-items > li:hover {
      -webkit-transform: translateX(15px);
              transform: translateX(15px); }

.left-menu.opened {
  -webkit-transform: translateX(0);
          transform: translateX(0); }

ul.slide-dots {
  -webkit-transition-duration: 1s;
          transition-duration: 1s;
  z-index: 1;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 0;
  list-style: none;
  padding: 15px; }
  ul.slide-dots > li {
    width: 16px;
    height: 16px;
    border: solid 2px #b0945d;
    border-radius: 8px;
    margin: 13px 0;
    cursor: pointer;
    padding: 2px;
    box-sizing: border-box;
    cursor: pointer; }
    ul.slide-dots > li > a {
      width: 8px;
      height: 8px;
      background-color: #b0945d;
      display: block;
      border-radius: 4px;
      opacity: 0;
      -webkit-transition-duration: 120ms;
              transition-duration: 120ms; }
  ul.slide-dots > li.active > a {
    opacity: 1; }

ul.slide-dots.hidden {
  -webkit-transform: translate(-100%, -50%);
          transform: translate(-100%, -50%); }

.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0; }
  .container > .touch-container {
    -webkit-transition-duration: 1s;
            transition-duration: 1s; }
    .container > .touch-container .container-content {
      display: block;
      width: 100%;
      -webkit-transition-duration: 1s;
              transition-duration: 1s; }
      .container > .touch-container .container-content > .page {
        padding: 100px 15px 100px 15px;
        box-sizing: border-box;
        width: 100%;
        height: 100vh;
        color: #fff; }
        @media screen and (max-width: 767px) {
          .container > .touch-container .container-content > .page {
            padding: 60px 15px 30px 15px; } }
  .container > .scroll-img {
    position: fixed;
    bottom: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    .container > .scroll-img > a {
      display: block; }
      .container > .scroll-img > a > img {
        width: 60px; }
    @media screen and (max-width: 767px) {
      .container > .scroll-img {
        bottom: 15px; }
        .container > .scroll-img > a > img {
          width: 40px; } }
  .container > .scroll-img.hidden {
    opacity: 0;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s; }
  .container > .touch-container .container-content.page-0 {
    -webkit-transform: translateY(-100vh);
            transform: translateY(-100vh); }
  .container > .touch-container .container-content.page-1 {
    -webkit-transform: translateY(-200vh);
            transform: translateY(-200vh); }
  .container > .touch-container .container-content.page-2 {
    -webkit-transform: translateY(-300vh);
            transform: translateY(-300vh); }
  .container > .touch-container .container-content.page-3 {
    -webkit-transform: translateY(-400vh);
            transform: translateY(-400vh); }
  .container > .touch-container .container-content.page-4 {
    -webkit-transform: translateY(-500vh);
            transform: translateY(-500vh); }
  .container > .touch-container .container-content.page-5 {
    -webkit-transform: translateY(-600vh);
            transform: translateY(-600vh); }
  .container > .touch-container .container-content.page-6 {
    -webkit-transform: translateY(-700vh);
            transform: translateY(-700vh); }
  .container > .touch-container .container-content.page-7 {
    -webkit-transform: translateY(-800vh);
            transform: translateY(-800vh); }
  .container > .touch-container .container-content.page-8 {
    -webkit-transform: translateY(-900vh);
            transform: translateY(-900vh); }
  .container > .touch-container .container-content.page-9 {
    -webkit-transform: translateY(-1000vh);
            transform: translateY(-1000vh); }
  .container > .touch-container .container-content.page-10 {
    -webkit-transform: translateY(-1100vh);
            transform: translateY(-1100vh); }

.page.home {
  text-align: center;
  position: relative;
  background-image: url(/static/media/home-back.8c85a9b0.png);
  background-repeat: no-repeat;
  background-position: center center; }
  .page.home img.logo {
    padding: 0 15px;
    width: 100%;
    max-width: 540px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    box-sizing: border-box; }

.page.gradient-back {
  position: relative;
  background: #fff; }
  .page.gradient-back > .gradient-header-back {
    background-image: -webkit-gradient(linear, left top, left bottom, from(#141415), color-stop(80%, rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0)));
    background-image: linear-gradient(180deg, #141415 0%, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110px;
    opacity: 0.6; }
  .page.gradient-back > .gradient-bottom-back {
    width: 100%;
    height: 235px;
    opacity: 0.4;
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#141415), color-stop(80%, rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0)));
    background-image: linear-gradient(0deg, #141415, rgba(0, 0, 0, 0.1) 80%, rgba(0, 0, 0, 0) 100%);
    z-index: -1000; }

.microphone-page {
  background-color: white;
  position: relative;
  color: #231f20 !important; }
  .microphone-page > .microphone-content {
    margin-top: 75px;
    padding: 0 45px;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    max-width: 1110px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 133px;
    grid-column-gap: 0px;
    grid-row-gap: 0px; }
    .microphone-page > .microphone-content > .header {
      margin: 30px;
      color: #b0945d;
      font-size: 60px;
      font-weight: 300;
      grid-area: 1 / 1 / 2 / 2; }
    .microphone-page > .microphone-content > .content {
      margin: 30px;
      font-size: 18px;
      font-weight: 700;
      grid-area: 2 / 1 / 3 / 2; }
      .microphone-page > .microphone-content > .content > .example-video > a {
        color: #b0945d;
        text-decoration: none;
        font-weight: 500; }
    .microphone-page > .microphone-content > .image {
      margin: 30px;
      grid-area: 1 / 2 / 3 / 3; }
      .microphone-page > .microphone-content > .image > img {
        width: 100%;
        max-width: 540px; }
    @media screen and (max-width: 720px) {
      .microphone-page > .microphone-content {
        margin-top: -25px;
        text-align: center;
        grid-template-columns: 100%;
        grid-template-rows: unset; }
        .microphone-page > .microphone-content > .header {
          margin: 0px 15px 7px;
          grid-area: 2;
          font-size: 32px; }
        .microphone-page > .microphone-content > .image {
          margin: 0px 15px 15px;
          grid-area: 1;
          margin: 0;
          margin-bottom: 30px; }
          .microphone-page > .microphone-content > .image > img {
            max-width: 108px; }
        .microphone-page > .microphone-content > .content {
          margin: 0px 15px;
          font-size: 15px;
          grid-area: 3; } }

.howjoin-page {
  overflow: hidden;
  background-image: url(/static/media/howjoinback.3acb416e.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200vh; }
  .howjoin-page > .howjoin-content {
    max-width: 1110px;
    margin: auto; }
    .howjoin-page > .howjoin-content > h1 {
      margin: 0 15px 90px;
      font-size: 60px;
      font-weight: 300;
      text-align: center;
      color: #b0945d; }
      @media screen and (max-width: 767px) {
        .howjoin-page > .howjoin-content > h1 {
          font-size: 32px;
          margin-bottom: 0; } }
    .howjoin-page > .howjoin-content > .content-columns {
      width: 100%;
      display: inline-block; }
      .howjoin-page > .howjoin-content > .content-columns > div {
        width: 33.33333%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: center;
        color: #fff; }
        @media screen and (max-width: 960px) {
          .howjoin-page > .howjoin-content > .content-columns > div {
            width: 100%; } }
        @media screen and (max-width: 767px) {
          .howjoin-page > .howjoin-content > .content-columns > div {
            width: 33.33333%; }
            .howjoin-page > .howjoin-content > .content-columns > div > img {
              display: none; } }
        @media screen and (max-width: 767px) {
          .howjoin-page > .howjoin-content > .content-columns > div {
            padding: 0 40px;
            width: 100%;
            font-size: 15px; }
            .howjoin-page > .howjoin-content > .content-columns > div > img {
              display: none; }
            .howjoin-page > .howjoin-content > .content-columns > div > h3 {
              margin: 0 !important;
              font-size: 18px !important; } }
        .howjoin-page > .howjoin-content > .content-columns > div > h3 {
          margin: 20px;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #b0945d; }

.select-video-page {
  background-image: url(/static/media/select-video-back.1a00fea8.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200vh;
  padding: 0 0 0 0 !important; }
  .select-video-page > .select-video-content {
    position: relative;
    width: 100%;
    height: 100%; }
    .select-video-page > .select-video-content > .video-page-center {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      width: 100%; }
      .select-video-page > .select-video-content > .video-page-center > h1 {
        font-size: 60px;
        font-weight: 300;
        text-align: center;
        color: #b0945d;
        margin: 0; }
        @media screen and (max-width: 767px) {
          .select-video-page > .select-video-content > .video-page-center > h1 {
            font-size: 32px; } }
      .select-video-page > .select-video-content > .video-page-center > .videos {
        margin: auto;
        text-align: center;
        width: 100%;
        max-width: 1580px; }
        .select-video-page > .select-video-content > .video-page-center > .videos > .video-content {
          margin: 20px;
          width: 355px;
          height: 200px;
          display: inline-block; }
          @media screen and (min-height: 600px) and (max-height: 750px) and (orientation: landscape) {
            .select-video-page > .select-video-content > .video-page-center > .videos > .video-content {
              width: 267px;
              height: 150px; } }
          @media screen and (max-width: 767px) {
            .select-video-page > .select-video-content > .video-page-center > .videos > .video-content {
              width: 140px;
              height: 79.6px;
              margin: 7.5px; } }
          @media screen and (max-width: 767px) {
            .select-video-page > .select-video-content > .video-page-center > .videos > .video-content {
              width: 140px;
              height: 79.6px;
              margin: 7.5px; } }

.video-modal > .modal-content {
  max-width: 1090px !important; }

.video-item {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%; }
  .video-item > img.poster {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 100%;
    z-index: 1; }
  .video-item > a.play {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2; }
    .video-item > a.play > img {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
      @media screen and (max-width: 767px) {
        .video-item > a.play > img {
          width: 36px; } }

.ios-video {
  max-width: 100%; }

.modals > .modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0; }
  .modals > .modal > .modal-content {
    width: calc(100% - 50px);
    max-width: 350px;
    height: auto;
    max-height: 640px;
    overflow-y: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: #d8d8d8;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    object-fit: contain;
    padding: 15px;
    box-sizing: border-box;
    border-radius: 4px; }

.modals > .modal.modal-0 {
  z-index: 10000; }

.modals > .modal.modal-1 {
  z-index: 10001; }

.modals > .modal.modal-2 {
  z-index: 10002; }

.modals > .modal.modal-3 {
  z-index: 10003; }

.modals > .modal.modal-4 {
  z-index: 10004; }

.modals > .modal.modal-5 {
  z-index: 10005; }

.modals > .modal.modal-6 {
  z-index: 10006; }

.modals > .modal.modal-7 {
  z-index: 10007; }

.modals > .modal.modal-8 {
  z-index: 10008; }

.modals > .modal.modal-9 {
  z-index: 10009; }

.modals > .modal.modal-10 {
  z-index: 10010; }

.loader-container {
  width: 100%;
  height: 100%;
  background-color: rgba(40, 44, 52, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30000; }
  .loader-container .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px; }
  .loader-container .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
            animation: lds-dual-ring 1.2s linear infinite; }

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.join-page {
  position: relative; }
  @media screen and (max-width: 767px) {
    .join-page {
      background-color: #807e75 !important; } }
  .join-page > .join-content {
    max-width: 540px;
    width: 100%;
    margin: auto;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
    @media screen and (max-width: 767px) {
      .join-page > .join-content {
        zoom: 0.92; } }
    @media screen and (min-height: 600px) and (max-height: 750px) and (orientation: landscape) {
      .join-page > .join-content {
        zoom: 0.9; } }
    @media screen and (max-width: 767px) {
      .join-page > .join-content {
        height: 100%; } }
    .join-page > .join-content > .title {
      padding: 30px; }
      @media screen and (max-width: 767px) {
        .join-page > .join-content > .title {
          position: inherit;
          width: 100%;
          margin-top: 60px;
          box-sizing: border-box;
          padding: 0; } }
      .join-page > .join-content > .title > h1 {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
        font-size: 60px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1em;
        letter-spacing: normal;
        text-align: center;
        color: #b0945d; }
        @media screen and (max-width: 767px) {
          .join-page > .join-content > .title > h1 {
            font-size: 24px;
            font-weight: 300;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: #ceac69; } }
    .join-page > .join-content > form.join-form {
      padding: 50px 95px;
      background-color: #807e75;
      object-fit: contain;
      border-radius: 8px;
      box-shadow: 0 4px 10px 2px rgba(100, 100, 100, 0.5); }
      @media screen and (max-width: 767px) {
        .join-page > .join-content > form.join-form {
          padding: 65px 42.5px 27px;
          height: 100%;
          box-sizing: border-box;
          background-color: transparent; } }
      @media screen and (min-width: 320px) and (max-width: 867px) and (orientation: landscape) and (min-height: 350px) {
        .join-page > .join-content > form.join-form {
          overflow-y: auto; } }
      .join-page > .join-content > form.join-form label,
      .join-page > .join-content > form.join-form input {
        color: #fff;
        outline: none;
        font-size: 13px; }
      .join-page > .join-content > form.join-form > .form-rows > .row {
        padding-top: 30px; }
        @media screen and (max-width: 767px) {
          .join-page > .join-content > form.join-form > .form-rows > .row {
            padding-top: 30px; } }
        .join-page > .join-content > form.join-form > .form-rows > .row .label-textbox-container.error label:not(.top) {
          color: #ffcf70; }
        .join-page > .join-content > form.join-form > .form-rows > .row .label-textbox-container.error > input {
          border-bottom-color: #ffcf70; }
      .join-page > .join-content > form.join-form > .form-rows > .row.file-row {
        padding-top: 11px; }
        .join-page > .join-content > form.join-form > .form-rows > .row.file-row > h4 {
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.39px;
          color: #fff;
          text-align: center;
          margin: 0;
          padding: 0;
          margin-bottom: 6px; }
        .join-page > .join-content > form.join-form > .form-rows > .row.file-row button.select-file {
          width: 100%;
          text-align: center;
          padding: 8px;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.39px;
          color: #b0945d;
          background-color: #fff;
          border: none;
          border-radius: 4px;
          cursor: pointer; }
        .join-page > .join-content > form.join-form > .form-rows > .row.file-row > .file-message {
          font-size: 9px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.25px;
          text-align: center;
          margin-top: 5px; }
        .join-page > .join-content > form.join-form > .form-rows > .row.file-row > .file-message.error {
          color: #ffcf70; }
      .join-page > .join-content > form.join-form > .form-rows > .row.radio-row {
        padding-top: 10px;
        margin-bottom: -17px; }
        .join-page > .join-content > form.join-form > .form-rows > .row.radio-row > label {
          float: left;
          padding-right: 33px;
          line-height: 30px; }
        .join-page > .join-content > form.join-form > .form-rows > .row.radio-row > ul {
          display: inline-block; }
      .join-page > .join-content > form.join-form > .form-rows > .row.radio-row.error > label {
        color: #ffcf70 !important; }
      .join-page > .join-content > form.join-form > .form-rows > .row.check-row {
        padding-top: 10px; }
        .join-page > .join-content > form.join-form > .form-rows > .row.check-row a {
          color: #fff;
          text-decoration: underline; }
      .join-page > .join-content > form.join-form > .form-rows > .row.check-row.error > .checkbox > .check-label {
        color: #ffcf70 !important; }
        .join-page > .join-content > form.join-form > .form-rows > .row.check-row.error > .checkbox > .check-label > a {
          color: #ffcf70 !important; }
      .join-page > .join-content > form.join-form > .form-rows > .row.join-row {
        padding-top: 10px; }
        .join-page > .join-content > form.join-form > .form-rows > .row.join-row > button {
          widows: 100%;
          background: #b0945d;
          border: none;
          border-radius: 4px;
          color: #fff;
          padding: 12px;
          font-size: 17px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: -0.39px;
          color: #fff;
          width: 100%;
          cursor: pointer; }

.sms-modal > .modal-content > h2 {
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  padding: 0;
  margin: 0; }

.sms-modal > .modal-content > input {
  font-size: 27px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.35px;
  text-align: center;
  color: #000;
  padding: 7px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px #a7a7a7;
  background-color: #fff;
  margin: 13px 0;
  font-family: Montserrat; }

.sms-modal > .modal-content > input.error {
  border-color: #ffcf70 !important; }

.sms-modal > .modal-content > button {
  font-size: 15px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-weight: bold;
  letter-spacing: -0.35px;
  text-align: center;
  color: #fff;
  background-color: #b0945d;
  border-radius: 4px;
  padding: 13px;
  width: 100%;
  box-sizing: border-box;
  border: none; }

.message-modal > .modal-content {
  text-align: center; }
  .message-modal > .modal-content > h2 {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #000000; }
  .message-modal > .modal-content > img {
    width: 50px;
    height: 50px; }
  .message-modal > .modal-content > button {
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    font-weight: bold;
    letter-spacing: -0.35px;
    text-align: center;
    color: #fff;
    background-color: #b0945d;
    border-radius: 4px;
    padding: 13px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    cursor: pointer; }

.term-modal > .modal-content {
  max-width: 540px !important; }

.react-datepicker-wrapper {
  width: 100%;
  position: relative;
  z-index: 2; }
  .react-datepicker-wrapper > .react-datepicker__input-container {
    width: 100%; }
    .react-datepicker-wrapper > .react-datepicker__input-container > input {
      width: 100%;
      border-width: 0;
      font-size: 13px;
      border-bottom: 1px solid;
      background-color: transparent; }

.react-datepicker-popper {
  z-index: 5 !important; }

.label-textbox-container {
  width: 100%;
  position: relative;
  outline: none; }
  .label-textbox-container > label {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 15px;
    -webkit-transition-duration: 500ms;
            transition-duration: 500ms;
    z-index: 1; }
  .label-textbox-container > label.top {
    -webkit-transform: translateY(-150%);
            transform: translateY(-150%);
    font-size: 10px;
    opacity: 0.7; }
  .label-textbox-container > input {
    position: relative;
    z-index: 2;
    width: 100%;
    border-width: 0;
    font-size: 13px;
    border-bottom: 1px solid;
    background-color: transparent; }

.checkbox {
  display: inline-block;
  width: 100%; }
  .checkbox > .check {
    width: 20px;
    height: 20px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px #fff;
    cursor: pointer;
    float: left;
    margin-right: 8px; }
    .checkbox > .check > img {
      width: 100%;
      padding: 3px 2px 2px;
      box-sizing: border-box;
      opacity: 0; }
  .checkbox > .check.checked > img {
    opacity: 1; }
  .checkbox > .check-label {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    color: #fff;
    display: block;
    float: left;
    width: calc(100% - 30px);
    margin-top: 0px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .checkbox > .check-label > span {
      text-decoration: underline;
      cursor: pointer; }
      .checkbox > .check-label > span:hover {
        color: #ddd; }
      .checkbox > .check-label > span:last-child {
        -webkit-margin-end: 3px;
                margin-inline-end: 3px; }

.modalMessage__title {
  font-size: 1.5rem;
  font-weight: 300; }

.modalMessage__text {
  font-weight: 300; }

.term-text {
  font-weight: 300; }

.modals.closed-modals > .modal {
  position: absolute;
  z-index: 25; }
  .modals.closed-modals > .modal > .modal-content {
    max-width: 640px;
    color: #282c34;
    padding: 25px 30px;
    text-align: center; }

.jury-page {
  background-image: url(/static/media/jury-back.32f02b1d.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200vh; }
  @media screen and (max-width: 767px) {
    .jury-page > .jury-content {
      zoom: 0.8; } }
  @media screen and (min-height: 600px) and (max-height: 750px) and (orientation: landscape) {
    .jury-page > .jury-content {
      zoom: 0.9; } }
  .jury-page > .jury-content > .title {
    text-align: center;
    margin-bottom: 154px; }
    @media screen and (max-width: 767px) {
      .jury-page > .jury-content > .title {
        margin-bottom: 17px; } }
    .jury-page > .jury-content > .title > h1 {
      display: inline;
      font-size: 60px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #b0945d; }
      @media screen and (max-width: 767px) {
        .jury-page > .jury-content > .title > h1 {
          font-size: 32px;
          font-weight: 300;
          text-align: center;
          color: #fff;
          border: none; } }
  .jury-page > .jury-content > ul.jury-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center; }
    @media screen and (max-width: 767px) {
      .jury-page > .jury-content > ul.jury-list {
        width: 250px;
        flex-direction: column;
        margin: auto; } }
    .jury-page > .jury-content > ul.jury-list > li {
      width: 33.33333%;
      max-width: 350px; }
      @media screen and (max-width: 767px) {
        .jury-page > .jury-content > ul.jury-list > li {
          width: 100%; } }
      .jury-page > .jury-content > ul.jury-list > li > .img {
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        margin: auto;
        display: block;
        width: 265px;
        height: 265px;
        overflow: hidden;
        border: 3px solid #b0945d;
        border-radius: 50%; }
        @media screen and (max-width: 767px) {
          .jury-page > .jury-content > ul.jury-list > li > .img {
            width: 73px;
            height: 73px; } }
        .jury-page > .jury-content > ul.jury-list > li > .img > img {
          width: 100%; }
      .jury-page > .jury-content > ul.jury-list > li > h3 {
        margin: 22px auto 30px auto;
        display: block;
        text-align: center;
        color: #b0945d; }
        @media screen and (max-width: 767px) {
          .jury-page > .jury-content > ul.jury-list > li > h3 {
            margin: 5.4px auto 2px auto; } }
      .jury-page > .jury-content > ul.jury-list > li > q {
        display: block;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: italic;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #fff; }
        @media screen and (max-width: 767px) {
          .jury-page > .jury-content > ul.jury-list > li > q {
            font-size: 14px;
            margin-bottom: 16px; } }

.term-page {
  width: 100%;
  position: relative; }
  .term-page > .absolute-content > .term-content {
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .term-page > .absolute-content > .term-content > .title {
      width: 505px;
      float: left;
      margin-right: 65px; }
      @media screen and (max-width: 1100px) {
        .term-page > .absolute-content > .term-content > .title {
          width: 450px; } }
      @media screen and (max-width: 890px) {
        .term-page > .absolute-content > .term-content > .title {
          width: 100% !important;
          text-align: center; } }
      @media screen and (max-width: 767px) {
        .term-page > .absolute-content > .term-content > .title {
          margin: auto; } }
      .term-page > .absolute-content > .term-content > .title > h1 {
        font-size: 90px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #b0945d;
        margin: 0; }
        @media screen and (max-width: 1100px) {
          .term-page > .absolute-content > .term-content > .title > h1 {
            font-size: 80px;
            margin-right: 65px; } }
        @media screen and (max-width: 890px) {
          .term-page > .absolute-content > .term-content > .title > h1 {
            font-size: 52px;
            margin-right: 0; } }
        @media screen and (max-width: 767px) {
          .term-page > .absolute-content > .term-content > .title > h1 {
            font-size: 32px; } }
    .term-page > .absolute-content > .term-content > .term-text {
      width: 540px;
      height: auto;
      max-height: 610px;
      overflow-y: auto;
      float: left;
      font-size: 18px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.45;
      letter-spacing: normal;
      color: #000000; }
      .term-page > .absolute-content > .term-content > .term-text::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
        border-radius: 1rem; }
      .term-page > .absolute-content > .term-content > .term-text::-webkit-scrollbar {
        width: 6px;
        background-color: #F5F5F5;
        border-radius: 1rem; }
      .term-page > .absolute-content > .term-content > .term-text::-webkit-scrollbar-thumb {
        background-color: #696969;
        border-radius: 1rem; }
      @media screen and (max-width: 1100px) {
        .term-page > .absolute-content > .term-content > .term-text {
          width: 460px; } }
      @media screen and (max-width: 890px) {
        .term-page > .absolute-content > .term-content > .term-text {
          width: 100%;
          text-align: center;
          padding: 40px 75px;
          box-sizing: border-box; } }
      @media screen and (max-width: 767px) {
        .term-page > .absolute-content > .term-content > .term-text {
          padding: 15px 30px;
          font-size: 13px;
          max-height: 500px;
          -webkit-margin-before: 0.5rem;
                  margin-block-start: 0.5rem; } }

