.modalMessage__title {
    font-size: 1.5rem;
    font-weight: 300;
}

.modalMessage__text {
    font-weight: 300;
}

.term-text {
    font-weight: 300;
}