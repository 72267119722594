@import "../variables.scss";
@import "../mobile.scss";
.microphone-page {
  background-color: white;
  position: relative;
  color: $color3 !important;

  > .microphone-content {
    margin-top: 75px;
    padding: 0 $default-space * 3;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 1110px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 133px;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    > .header {
      margin: 30px;
      color: $color2;
      font-size: 60px;
      font-weight: 300;
      grid-area: 1 / 1 / 2 / 2;
    }
    > .content {
      margin: 30px;
      font-size: 18px;
      font-weight: 700;
      grid-area: 2 / 1 / 3 / 2;
      > .example-video {
        > a {
          color: #b0945d;
          text-decoration: none;
          font-weight: 500;
          // z-index: -100;
        }
      }
    }
    > .image {
      margin: 30px;
      grid-area: 1 / 2 / 3 / 3;
      > img {
        width: 100%;
        max-width: 540px;
      }
    }
    @media screen and (max-width: 720px) {
      margin-top: -25px;
      text-align: center;
      grid-template-columns: 100%;
      grid-template-rows: unset;
      > .header {
        margin: 0px $default-space 7px;
        grid-area: 2;
        font-size: 32px;
      }
      > .image {
        margin: 0px $default-space 15px;
        grid-area: 1;
        margin: 0;
        margin-bottom: 30px;
        > img {
          max-width: 108px;
        }
      }
      > .content {
        margin: 0px $default-space;
        font-size: 15px;
        grid-area: 3;
      }
    }
  }
}
