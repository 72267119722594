@import "../variables.scss";
.howjoin-page {
  overflow: hidden;
  background-image: url(../../imgs/howjoinback.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200vh;
  > .howjoin-content {
    max-width: 1110px;
    margin: auto;

    > h1 {
      @media screen and (max-width: $mobile-max) {
        font-size: 32px;
        margin-bottom: 0;
      }
      margin: 0 $default-space 90px;
      font-size: 60px;
      font-weight: 300;
      text-align: center;
      color: $color2;
    }
    > .content-columns {
      width: 100%;
      display: inline-block;
      > div {
        @media screen and (max-width: 960px) {
          width: 100%;
        }
        @media screen and (max-width: $mobile-max) {
          > img {
            display: none;
          }
          width: (100%)/3;
        }
        @media screen and (max-width: $mobile-max) {
          padding: 0 40px;
          width: 100%;
          font-size: 15px;
          > img {
            display: none;
          }
          > h3 {
            margin: 0 !important;
            font-size: 18px !important;
          }
        }
        width: (100%)/3;
        float: left;
        box-sizing: border-box;
        text-align: center;
        font-size: 18px;
        line-height: 1.44;
        letter-spacing: normal;
        text-align: center;
        color: $text-color;
        > h3 {
          margin: 20px;
          font-size: 24px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color2;
        }
      }
    }
  }
}
