@import "../variables.scss";
@import "date-picker.scss";
.label-textbox-container {
  width: 100%;
  position: relative;
  outline: none;
  > label {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 15px;
    transition-duration: 500ms;
    z-index: 1;
  }
  > label.top {
    transform: translateY(-150%);
    font-size: 10px;
    opacity: 0.7;
  }
  > input {
    position: relative;
    z-index: 2;
    width: 100%;
    border-width: 0;
    font-size: 13px;
    border-bottom: 1px solid;
    background-color: transparent;
  }
}
