@import "../variables.scss";
.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  > .touch-container {
    transition-duration: 1s;
    .container-content {
      display: block;
      width: 100%;
      > .page {
        padding: 100px $default-space 100px $default-space;
        @media screen and (max-width: $mobile-max) {
          padding: $default-space * 4 $default-space $default-space * 2
            $default-space;
        }
        box-sizing: border-box;
        width: 100%;
        height: 100vh;
        color: $text-color;
      }
      transition-duration: 1s;
    }
  }
  > .scroll-img {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    > a {
      display: block;
      > img {
        width: 60px;
      }
    }
    @media screen and (max-width: $mobile-max) {
      bottom: $default-space;
      > a {
        > img {
          width: 40px;
        }
      }
    }
  }
  > .scroll-img.hidden {
    opacity: 0;
    transition-duration: 0.5s;
  }

  @for $i from 0 through 10 {
    > .touch-container .container-content.page-#{$i} {
      transform: translateY((-1 -$i) * 100vh);
    }
  }
}
