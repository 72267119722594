@import "../variables.scss";
.page.home {
  text-align: center;
  position: relative;
  background-image: url(../../imgs/home-back.png);
  background-repeat: no-repeat;
  background-position: center center;
  img.logo {
    padding: 0 $default-space;
    width: 100%;
    max-width: 540px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
  }
}
