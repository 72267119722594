@import "../variables";
@import "../mobile.scss";
$menu-back-color: #020202;
/* Set the background color of body to tan */

.left-menu {
  position: absolute;
  left: 0%;
  top: 0%;
  width: 400px;
  height: 100%;
  transform: translateX(-100%);
  transition-duration: 0.5s;
  z-index: 500;
  background-image: linear-gradient(
    to bottom,
    #a98e5a,
    rgba(193, 171, 122, 0.66) 70%,
    rgba(212, 193, 147, 0) 98%
  );
  @media screen and (max-width: $mobile-max) {
    width: 100%;
    background-color: $menu-back-color;
    background-image: unset;
    text-align: center;
  }

  > ul.menu-items {
    margin: 0;
    padding: 0;
    margin-top: 180px;
    margin-left: $default-space;
    list-style: none;
    font-size: 1.5em;
    font-weight: bolder;
    @media screen and (max-height: 700px) {
      margin-top: 50px !important;
      font-size: 1.3em;
    }
    @media screen and (max-width: $mobile-max) {
      margin-left: 0;
    }
    > li {
      margin: 0;
      padding: 0;
      line-height: 2.2em;
      > a {
        color: $text-color;
        text-decoration: none;
        display: block;
      }
      transition-duration: 0.2s;
    }
    > li:hover {
      transform: translateX($default-space);
    }
  }
}
.left-menu.opened {
  transform: translateX(0);
}

ul.slide-dots {
  transition-duration: 1s;
  z-index: 1;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  list-style: none;
  padding: 15px;
  > li {
    width: 16px;
    height: 16px;
    border: solid 2px $color2;
    border-radius: 8px;
    margin: 13px 0;
    cursor: pointer;
    padding: 2px;
    box-sizing: border-box;
    cursor: pointer;
    > a {
      width: 8px;
      height: 8px;
      background-color: $color2;
      display: block;
      border-radius: 4px;
      opacity: 0;
      transition-duration: 120ms;
    }
  }
  > li.active {
    > a {
      opacity: 1;
    }
  }
}
ul.slide-dots.hidden {
  transform: translate(-100%, -50%);
}
