@import "./variables.scss";
@import "font.scss";
.App-link {
  color: #09d3ac;
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  background-color: $back-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
img {
  user-select: none;
}
// .grecaptcha-badge {
//   visibility: hidden;
// }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
* {
  outline: none;
}
