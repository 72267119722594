@import "../variables.scss";
.join-page {
  position: relative;
  @media screen and (max-width: $mobile-max) {
    background-color: $form-back !important;
  }
  > .join-content {
    @media screen and (max-width: $mobile-max) {
      zoom: 0.92;
    }
    @media screen and (min-height: $desktop-min-height) and (max-height: $desktop-max-height) and (orientation: landscape) {
      zoom: 0.9;
    }
    max-width: 540px;
    width: 100%;
    margin: auto;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media screen and (max-width: $mobile-max) {
      height: 100%;
    }
    > .title {
      @media screen and (max-width: $mobile-max) {
        position: inherit;
        width: 100%;
        margin-top: 60px;
        box-sizing: border-box;
        padding: 0;
      }
      padding: 30px;
      > h1 {
        width: fit-content;
        margin: auto;
        font-size: 60px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1em;
        letter-spacing: normal;
        text-align: center;
        color: $color2;
        @media screen and (max-width: $mobile-max) {
          font-size: 24px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: #ceac69;
        }
      }
    }
    > form.join-form {
      @media screen and (max-width: $mobile-max) {
        padding: 65px 42.5px 27px;
        height: 100%;
        box-sizing: border-box;
        background-color: transparent;
      }
      @media screen and (min-width: $mobile-min) and (max-width: $mobile-max+100) and (orientation: landscape) and (min-height: 350px) {
        overflow-y: auto;
      }
      padding: 50px 95px;
      background-color: $form-back;
      object-fit: contain;
      border-radius: 8px;
      box-shadow: 0 4px 10px 2px rgba(100, 100, 100, 0.5);
      label,
      input {
        color: $text-color;
        outline: none;
        font-size: 13px;
      }
      > .form-rows {
        > .row {
          @media screen and (max-width: $mobile-max) {
            padding-top: 30px;
          }
          padding-top: 30px;
          .label-textbox-container.error {
            label:not(.top) {
              color: $error-color;
            }
            > input {
              border-bottom-color: $error-color;
            }
          }
        }
        > .row.file-row {
          padding-top: 11px;
          > h4 {
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.39px;
            color: $text-color;
            text-align: center;
            margin: 0;
            padding: 0;
            margin-bottom: 6px;
          }
          button.select-file {
            width: 100%;
            text-align: center;
            padding: 8px;
            font-size: 15px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.39px;
            color: $color2;
            background-color: $text-color;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
          > .file-message {
            font-size: 9px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.25px;
            text-align: center;
            margin-top: 5px;
          }
          > .file-message.error {
            color: $error-color;
          }
        }
        > .row.radio-row {
          padding-top: 10px;
          margin-bottom: -17px;
          > label {
            float: left;
            padding-right: 33px;
            line-height: 30px;
          }
          > ul {
            display: inline-block;
          }
        }
        > .row.radio-row.error {
          > label {
            color: $error-color !important;
          }
        }
        > .row.check-row {
          padding-top: 10px;
          a {
            color: $text-color;
            text-decoration: underline;
          }
        }
        > .row.check-row.error {
          > .checkbox {
            > .check-label {
              color: $error-color !important;
              > a {
                color: $error-color !important;
              }
            }
          }
        }
        > .row.join-row {
          padding-top: 10px;
          > button {
            widows: 100%;
            background: $color2;
            border: none;
            border-radius: 4px;
            color: $text-color;
            padding: 12px;
            font-size: 17px;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: -0.39px;
            color: $text-color;
            width: 100%;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.sms-modal {
  > .modal-content {
    > h2 {
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: $black;
      padding: 0;
      margin: 0;
    }
    > input {
      font-size: 27px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -0.35px;
      text-align: center;
      color: $black;
      padding: 7px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 4px;
      border: solid 1px #a7a7a7;
      background-color: $text-color;
      margin: 13px 0;
      font-family: Montserrat;
    }
    > input.error {
      border-color: $error-color !important;
    }
    > button {
      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      font-weight: bold;
      letter-spacing: -0.35px;
      text-align: center;
      color: $text-color;
      background-color: $color2;
      border-radius: 4px;
      padding: 13px;
      width: 100%;
      box-sizing: border-box;
      border: none;
    }
  }
}

.message-modal {
  > .modal-content {
    text-align: center;
    > h2 {
      font-size: 15px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #000000;
    }
    > img {
      width: 50px;
      height: 50px;
    }
    > button {
      font-size: 15px;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      font-weight: bold;
      letter-spacing: -0.35px;
      text-align: center;
      color: $text-color;
      background-color: $color2;
      border-radius: 4px;
      padding: 13px;
      width: 100%;
      box-sizing: border-box;
      border: none;
      cursor: pointer;
    }
  }
}
.term-modal > .modal-content {
  max-width: 540px !important;
}
