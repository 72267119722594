$back-color: #282c34;
$text-color: #fff;
$color1: #9fa1a4;
$color2: #b0945d;
$color3: #231f20;
$form-back: #807e75;
$hamburger-color: #e2e2e2;
$error-color: #ffcf70;
$modal-back: #d8d8d8;
$black: #000;
$hover-color: #ddd;
$default-space: 15px;

$mobile-max: 767px;
$mobile-min: 320px;

$desktop-min-height: 600px;
$desktop-max-height: 750px;
