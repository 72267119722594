@import "../variables";
$diff: 300%;

.top-menu {
  position: relative;
  z-index: 1000;
  margin: 15px;
  > .top-menu-hamburger {
    position: absolute;
    width: 40px;
    height: 32px;
    top: 0;
    left: 0;
    box-sizing: border-box;
    > .bar {
      margin: 0 5px;
      width: 100%;
      position: absolute;
      height: 4px;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      transition: .7s;
      border-radius: 2px;
      background-color: $hamburger-color;
      @media screen and (max-width: $mobile-max) {
        background-color: $color1;
      }
    }
    .bar:first-child {
      transform: unset;
      top: 0;
    }
    .bar:last-child {
      transform: unset;
      top: unset;
      bottom: 0;
    }
  }
  > img.logo {
    height: 45px;
    position: absolute;
    left: 75px;
    top: -5px;
    transition-duration: 1s;
    transition-property: opacity;
    @media screen and (max-width: $mobile-max) {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  > img.logo.hide {
    opacity: 0;
  }
}
.top-menu.opened {
  > .top-menu-hamburger {
    > .bar:not(:first-child):not(:last-child) {
      opacity: 0;
    }
    > .bar:first-child {
      top: 50%;
      transform: rotate(45deg);
    }
    > .bar:last-child {
      top: 50%;
      transform: rotate(-45deg);
    }
  }
}
