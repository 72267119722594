@import "../variables.scss";
.page.gradient-back {
  position: relative;
  background: $text-color;
  > .gradient-header-back {
    background-image: linear-gradient(
      180deg,
      rgba(20, 20, 21, 1) 0%,
      rgba(0, 0, 0, 0.1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110px;
    opacity: 0.6;
  }
  > .gradient-bottom-back {
    width: 100%;
    height: 235px;
    opacity: 0.4;
    position: absolute;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(
      0deg,
      #141415,
      rgba(0, 0, 0, 0.1) 80%,
      rgba(0, 0, 0, 0) 100%
    );
    z-index: -1000;
  }
}
