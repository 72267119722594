@import "../variables.scss";
@import "../mobile.scss";
.select-video-page {
  background-image: url("../../imgs/select-video-back.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200vh;
  padding: 0 0 0 0 !important;
  > .select-video-content {
    position: relative;
    width: 100%;
    height: 100%;
    > .video-page-center {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      > h1 {
        font-size: 60px;
        font-weight: 300;
        text-align: center;
        color: $color2;
        margin: 0;
        @media screen and (max-width: $mobile-max) {
          font-size: 32px;
        }
      }
      > .videos {
        margin: auto;
        text-align: center;
        width: 100%;
        max-width: 1580px;
        > .video-content {
          margin: 20px;
          width: 355px;
          height: 200px;
          display: inline-block;
          @media screen and (min-height: $desktop-min-height) and (max-height: $desktop-max-height) and (orientation: landscape) {
            width: 267px;
            height: 150px;
          }
          @media screen and (max-width: $mobile-max) {
            width: 140px;
            height: 79.6px;
            margin: 7.5px;
          }
          @media screen and (max-width: $mobile-max) {
            width: 140px;
            height: 79.6px;
            margin: 7.5px;
          }
        }
      }
    }
  }
}

.video-modal {
  > .modal-content {
    max-width: 1090px !important;
  }
}
