@import "../variables.scss";
.term-page {
  width: 100%;
  position: relative;
  > .absolute-content {
    > .term-content {
      margin: auto;
      width: fit-content;
      > .title {
        width: 505px;
        float: left;
        margin-right: 65px;
        @media screen and (max-width: 1100px) {
          width: 450px;
        }
        @media screen and (max-width: 890px) {
          width: 100% !important;
          text-align: center;
        }
        @media screen and (max-width: $mobile-max) {
          margin: auto;
        }
        > h1 {
          font-size: 90px;
          font-weight: 300;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $color2;
          margin: 0;
          @media screen and (max-width: 1100px) {
            font-size: 80px;
            margin-right: 65px;
          }
          @media screen and (max-width: 890px) {
            font-size: 52px;
            margin-right: 0;
          }
          @media screen and (max-width: $mobile-max) {
            font-size: 32px;
          }
        }
      }
      > .term-text {
        width: 540px;
        height: auto;
        max-height: 610px;
        overflow-y: auto;
        float: left;
        font-size: 18px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: normal;
        color: #000000;

        &::-webkit-scrollbar-track
        {
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #F5F5F5;
          border-radius: 1rem;
        }

        &::-webkit-scrollbar
        {
          width: 6px;
          background-color: #F5F5F5;
          border-radius: 1rem;
        }

        &::-webkit-scrollbar-thumb
        {
          background-color: #696969;
          border-radius: 1rem;
        }

        @media screen and (max-width: 1100px) {
          width: 460px;
        }
        @media screen and (max-width: 890px) {
          width: 100%;
          text-align: center;
          padding: 40px 75px;
          box-sizing: border-box;
        }
        @media screen and (max-width: $mobile-max) {
          padding: 15px 30px;
          font-size: 13px;
          max-height: 500px;
          margin-block-start: 0.5rem;
        }
      }
    }
  }
}
