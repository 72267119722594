@import "../variables.scss";
.modals {
  > .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, 0.8);
    top: 0;
    left: 0;
    > .modal-content {
      width: calc(100% - 50px);
      max-width: 350px;
      height: auto;
      max-height: 640px;
      overflow-y: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $modal-back;
      transform: translate(-50%, -50%);
      object-fit: contain;
      padding: 15px;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }
  @for $i from 0 through 10 {
    > .modal.modal-#{$i} {
      z-index: ($i + 10000);
    }
  }
}

.loader-container {
  width: 100%;
  height: 100%;
  background-color: rgba($back-color, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30000;
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
