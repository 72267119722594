@import "../variables.scss";
.checkbox {
  display: inline-block;
  width: 100%;
  > .check {
    width: 20px;
    height: 20px;
    object-fit: contain;
    border-radius: 4px;
    border: solid 1px $text-color;
    cursor: pointer;
    float: left;
    margin-right: 8px;
    > img {
      width: 100%;
      padding: 3px 2px 2px;
      box-sizing: border-box;
      opacity: 0;
    }
  }
  > .check.checked {
    > img {
      opacity: 1;
    }
  }
  > .check-label {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.25px;
    color: $text-color;
    display: block;
    float: left;
    width: calc(100% - 30px);
    margin-top: 0px;
    user-select: none;

    > span {
      text-decoration: underline;
      cursor: pointer;
      
      &:hover {
        color: $hover-color;
      }

      &:last-child {
        margin-inline-end: 3px;
      }
      
    }
  }
}
