@import "../variables.scss";
.modals.closed-modals {
  > .modal {
    position: absolute;
    z-index: 25;
    > .modal-content {
      max-width: 640px;
      color: $back-color;
      padding: 25px 30px;
      text-align: center;
    }
  }
}
