@import "../variables.scss";
.jury-page {
  background-image: url("../../imgs/jury-back.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 200vh;
  > .jury-content {
    @media screen and (max-width: $mobile-max) {
      zoom: 0.8;
    }

    @media screen and (min-height: $desktop-min-height) and (max-height: $desktop-max-height) and (orientation: landscape) {
      zoom: 0.9;
    }

    > .title {
      text-align: center;
      margin-bottom: 154px;
      @media screen and (max-width: $mobile-max) {
        margin-bottom: 17px;
      }
      > h1 {
        display: inline;
        font-size: 60px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $color2;
        @media screen and (max-width: $mobile-max) {
          font-size: 32px;
          font-weight: 300;
          text-align: center;
          color: $text-color;
          border: none;
        }
      }
    }
    > ul.jury-list {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      @media screen and (max-width: $mobile-max) {
        width: 250px;
        flex-direction: column;
        margin: auto;
      }
      > li {
        width: (100% / 3);
        @media screen and (max-width: $mobile-max) {
          width: 100%;
        }
        max-width: 350px;
        > .img {
          user-select: none;
          margin: auto;
          display: block;
          width: 265px;
          height: 265px;
          overflow: hidden;
          border: 3px solid $color2;
          border-radius: 50%;
          @media screen and (max-width: $mobile-max) {
            width: 73px;
            height: 73px;
          }
          > img {
            width: 100%;
          }
        }

        > h3 {
          margin: 22px auto 30px auto;
          display: block;
          text-align: center;
          color: $color2;
          @media screen and (max-width: $mobile-max) {
            margin: 5.4px auto 2px auto;
          }
        }
        > q {
          display: block;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: italic;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          color: $text-color;
          @media screen and (max-width: $mobile-max) {
            font-size: 14px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
