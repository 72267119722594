@import "../variables";
.video-item {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  > img.poster {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    z-index: 1;
  }
  > a.play {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    > img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      @media screen and (max-width: $mobile-max) {
        width: 36px;
      }
    }
  }
}
